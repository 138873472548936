/*@import '../../node_modules/milligram/dist/milligram.min.css';*/

/* font for Global Header */
/*@import url('https://fonts.googleapis.com/css?family=Playfair+Display|Heebo|Inconsolata');*/

header.cleverorc {
  font-family: 'Playfair Display', serif;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 1.5em;
  font-weight: 700;

  color: black;

  text-shadow: 3px 3px 3px rgba(0,0,0, 0.2);

  background: linear-gradient(0.25turn,  #68b04d 0%, #1c8a43 80%,rgb(16, 107, 19) 100%);

  height: 48px;
}

header.cleverorc div {
  padding-left: 52px;
  height: 100%;
}

header.cleverorc > div > span, header.cleverorc > div > a {
  vertical-align: middle;
}

.patreonLink {
  position: relative;
  display: block;
  float: right;
  height: 100%;
  margin-right: 0.25em;
}

.patreonLink img{
  width: 150px;
  margin: auto;
  position: absolute;
  top: 50%;
  bottom: 50%;
  right: 0;
}

@media screen and (max-width: 359px) {
  
  header.cleverorc span {
    font-size: 0.9em;
    line-height: 48px;
    vertical-align: middle;
  }

  .patreonLink img{
    width: 100px;
    vertical-align: middle;
  }
}

@media screen and (max-width: 600px) and (min-width: 360px) {
  header.cleverorc span {
    font-size: 1em;
    line-height: 48px;
    vertical-align: middle;
  }
  .patreonLink img{
    width: 120px;
    vertical-align: middle;
  }
}

/* Styles to do css only image swap on hover for menu button */
label.nav img {
  cursor: pointer;
}
label.nav img:last-child {
  display: none;  
}
label.nav:hover img:last-child {
  display: block;  
}
label.nav:hover img:first-child {
  display: none;  
}
label.nav:active img:last-child {
  display: none;  
}
label.nav:active img:first-child {
  display: block;  
}

/* These 3 styles are probably specific to MonsterSearch...*/
.ReactTable .rt-td {
    padding: 0;
}

h3 {
    padding-left: 1em;
}

.hidden {
    display:none;
}

/*Milligram customization
.button,
button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  background-color: #9b4dca;
  border: 0.1rem solid #9b4dca;
  border-radius: .4rem;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1.1rem;
  font-weight: 700;
  height: 3.8rem;
  letter-spacing: .1rem;
  line-height: 3.8rem;
  padding: 0 3.0rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
}*/

.dmScreen button, .monsteradvancer button {
  border-radius: .4em;
  cursor: pointer;
  font-size: 0.7em;
  padding: 0.6em 1.5em;
  letter-spacing: 0.1em;
  text-align: center;
  white-space: nowrap;
  margin: 0.2em;
}

button.greenAwesome {
    font-family: 'Heebo', Helvetica, Arial, sans-serif;
    font-weight: 400;
    text-transform: inherit;
    background: linear-gradient(0.5turn,  rgb(39, 89, 45) 0%, rgb(16, 107, 19) 50%,rgb(48, 190, 51) 100%);
    border: 0.1rem solid rgb(39, 89, 45);
    border-radius: 2rem;
    color: #fff;
    box-shadow: 2px 2px 5px rgb(87, 109, 117);
    transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
}

button.greenAwesome:hover {
    color: #000;
    border: 1px solid #006398;
    box-shadow: 2px 2px 20px #AFE9FF;
    /*transform: scale(0.96)*/
  }

  button.greenAwesome:active {
    color: #fff;
    background: rgb(33, 204, 39);
  }

  button.yellowAwesome {
    font-family: 'Heebo', Helvetica, Arial, sans-serif;
    font-weight: 400;
    text-transform: inherit;
    background: linear-gradient(0.5turn,  rgb(255, 217, 1) 0%, rgb(255, 166, 1) 80%,rgb(207, 131, 0) 100%);
    border: 0.1rem solid #FFC107;
    border-radius: 2rem;
    color: #000;
    box-shadow: 2px 2px 5px rgb(87, 109, 117);
    transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
}

button.yellowAwesome:hover {
    color: #fff;
    border: 1px solid #006398;
    box-shadow: 2px 2px 20px #AFE9FF;
    transform: scale(0.96)
  }

  button.yellowAwesome:active {
    color: #000;
    background: #FFC107;
  }

  button.orangeAwesome {
    font-family: 'Heebo', Helvetica, Arial, sans-serif;
    font-weight: 400;
    text-transform: inherit;
    background: linear-gradient(0.5turn,  rgb(255, 136, 1) 0%, rgb(189, 99, 2) 80%,rgb(124, 65, 1) 100%);
    border: 0.1rem solid rgb(189, 99, 2);
    border-radius: 2rem;
    color: #fff;
    box-shadow: 2px 2px 5px rgb(87, 109, 117);
    transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
}

button.orangeAwesome:hover {
    color: #fff;
    border: 1px solid #006398;
    box-shadow: 2px 2px 20px #AFE9FF;
    transform: scale(0.96)
  }

  button.orangeAwesome:active {
    color: #fff;
    background: rgb(255, 136, 1);
  }

  button.pinkAwesome {
    font-family: 'Heebo', Helvetica, Arial, sans-serif;
    font-weight: 400;
    text-transform: inherit;
    background: linear-gradient(0.5turn,    #e91e63 0%, #ec3b79 80%,#ec6695 100%);
    border: 0.1rem solid   #e91e63;
    border-radius: 2rem;
    color: #fff;
    box-shadow: 2px 2px 5px rgb(87, 109, 117);
    transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
}

button.pinkAwesome:hover {
    color: #fff;
    border: 1px solid #006398;
    box-shadow: 2px 2px 20px #AFE9FF;
    transform: scale(0.96)
  }

  button.pinkAwesome:active {
    color: #fff;
    background: #ec6695;
  }

  button.blueAwesome {
    font-family: 'Heebo', Helvetica, Arial, sans-serif;
    font-weight: 400;
    text-transform: inherit;
    background: linear-gradient(0.5turn, rgb(0, 90, 207) 0%, rgb(20, 0, 121) 50%,rgb(39, 1, 255) 100%);
    border: 0.1rem solid rgb(20, 0, 121);
    border-radius: 2rem;
    color: #fff;
    box-shadow: 2px 2px 5px rgb(87, 109, 117);
    transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
}

button.blueAwesome:hover {
    color: #fff;
    border: 1px solid #006398;
    box-shadow: 2px 2px 20px #AFE9FF;
    transform: scale(0.96)
  }

  button.blueAwesome:active {
    color: #fff;
    background: rgb(0, 90, 207);
  }

  button.tealAwesome {
    font-family: 'Heebo', Helvetica, Arial, sans-serif;
    font-weight: 400;
    text-transform: inherit;
    background: linear-gradient(0.5turn, #03a9f4 20%, #2196f3 50%,#3f51b5 100%);
    border: 0.1rem solid #009688;
    border-radius: 2rem;
    color: #fff;
    box-shadow: 2px 2px 5px rgb(87, 109, 117);
    transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
}

button.tealAwesome:hover {
    color: #fff;
    border: 1px solid #006398;
    box-shadow: 2px 2px 20px #AFE9FF;
    transform: scale(0.96)
  }

  button.tealAwesome:active {
    color: #fff;
    background: #03a9f4;
  }

  button.purpleAwesome {
    font-family: 'Heebo', Helvetica, Arial, sans-serif;
    font-weight: 400;
    text-transform: inherit;
    background: linear-gradient(0.5turn, #470b6d 0%, #9b4dca 80%,#c77af7 100%);
    border: 0.1rem solid #9b4dca;
    border-radius: 2rem;
    color: #fff;
    box-shadow: 2px 2px 5px rgb(87, 109, 117);
    transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -moz-transition-duration: 0.3s;
}

button.purpleAwesome:hover {
    color: #fff;
    border: 1px solid #006398;
    box-shadow: 2px 2px 20px #AFE9FF;
    transform: scale(0.96)
  }

  button.purpleAwesome:active {
    color: #fff;
    background: #c77af7;
  }

  select.co-awesome {
    height: 2em;
    font-family: 'Inconsolata', monospace;
  }

  .semi-square {
    -webkit-border-radius: .4em;
    -moz-border-radius: .4em;
    border-radius: .4em;
 }

 .co-bold {
   font-weight: bold;
 }

  input.co-awesome {
    height: 2em;
    width: 2.5em;
    border-radius: .4em;
    border:1px solid #cccccc;
    padding: 0 0 0 1em;
    font-family: 'Inconsolata', monospace;
  }

.valuePair {
  display:inline-block;
  position: relative;
  height: 2em;
  font-family: 'Inconsolata', monospace;
  margin: 0.5em 0.25em;
}

  .valuePair > label {
    border: 1px solid green;
    background-color: lightgreen;
    display: inline-block;
    border-radius: .4em 0 0 .4em;
    position: absolute;
    padding: 0 .5em;
    line-height: 2em;
    height: 100%;
  }

  .valuePair > input {
    display:inline-block;
    height: 100%;
    border-radius: 0 .4em .4em 0;
    width: 2.5em;
    margin-left: 2.8em;
  }


