
* {-webkit-font-smoothing: antialiased;} /*https://stackoverflow.com/a/27528244/1310765*/
.monsterDisplay {
    border: 1px solid black;
    text-align: left;
    padding: 1em;
    font: normal 13px 'Lato', sans-serif;
}

.monsterFieldLabel, .bLabel {
    font-weight: bold;
    color: black;
}

.leftAndRight {
    display: flex;
    justify-content: space-between;
} 

div.sbName {
    color: white;
    background-color: black;
    padding: 0.3em;
}
div.sbName .bLabel {
    color: white;
}

.sbSectionHeader {
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    margin: 0.1em 0;
    text-transform: uppercase;
    font-size: 16px;
}

.sbLine {
    padding: 0.1em 0;
}

.sbSpells p {
    margin: 0.1em 0em 0.1em 1em;
}

.sbDescription {
    font: normal 14px 'PT Serif', serif;
}

.sbRaceSection p {
    display: inline;
}