.versionChoice {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    display: none;
}

.versionChoice img.pfImage {
    padding-left: 40px;
}

.oglImage {
    width: 200px;
}

.pfImage {
    width: 400px;
}

@media screen and (max-width:480px) {
    .versionChoice img.pfImage {
        padding-left: 0px;
    }
}

.co-panel {
    margin: 0.5em 0.5em 0 0.5em;
}

.co-panel .co-panel-header {
    border: 1px solid green;
    background-color: lightgreen;
    color: black;
    border-radius: 5px 5px 0 0;
    padding: 0.2em 0.5em;
}

.co-panel .co-panel-body {
    border: 1px solid green;
    border-radius: 0 0 5px 5px;
    padding: 0.5em 0.5em 0.2em 0.5em;
}

.co-select-container {
    max-width: 600px;
}

.monsteradvancer button.generateButton {
    padding: 0.5em 1em;
}

.monsterCustomization {
    display: flex;
    flex-wrap: wrap;
}

.monsterCustomization > div.co-panel.monsterFields {
    min-width: 320px;
    flex: 1 1 320px;
}
.monsterCustomization > div.co-panel.monsterStatBlock {
    min-width: 320px;
    flex: 1 1 66%;
}

.monsterCustomization {
    font-family: 'Lato', sans-serif;
}

.co-monsterName {
    font-size: 1.5em;
}

.co-select-container * {
    vertical-align: middle;
}