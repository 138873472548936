body {
    font-family: Helvetica, sans-serif;
  }
  
  .Autosuggest__container {
    background-color: blue;
    height: 3rem;
    padding: 1rem;
  }
  
  .react-autosuggest__container {
    height: 3rem;
    position: relative;
    width: 100%;
  }
  
  .react-autosuggest__input {
    box-sizing: border-box;
    height: inherit;
    position: absolute;
    width: 100%;
    
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
  }
  
  .react-autosuggest__input--focused {
    outline: none;
  }
  
  .react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  .react-autosuggest__suggestions-container {
    display: none;
  }
  
  .react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 2.6rem;
    width: calc(100% - 1px);
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    max-height: 500px;
    overflow-y: auto;
    z-index: 2;
  }
  
  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 5px 10px;
  }
  
  .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
  }
  