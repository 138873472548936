.flex-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .flex-item {
    order: 2;
  }

  .flex3 {
    flex: 3
  }
  
  .flex-item:nth-child(2) {
    order: 1;
    background-color: grey;
  }

.jsonDisplayBox {
  border: 1px solid black;
  padding: 0.5em;
  width: 100%;
  order: 3;
}