.monsterSearch {
    max-width: 500px;
    margin: 0 auto;
    padding: 1em 3em;
}

.monsterSearch .searchField {
    margin-top: 0.5em;
}

.monsterSearch .searchField label{
    display: inline;
}

.monsterSearch .searchField input[type="text"] {
    width: 100px;
}

.monsterSearch button.greenAwesome {
    padding: 0.5em 1em;
}

@media screen and (max-height:640px) {
    .searchResults {
        font-size: 1.5em;
    }
}