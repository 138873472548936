.createAButton {
    border: 1px solid gray;
    border-radius: 1em;
}
.createAButton .customDice input {
    width: 60px;
    margin-bottom: 0px;
}
.createAButton .customDieField {
    margin: 0 0.5em 0 0.5em;
}

@media screen and (max-width:450px) {
    .createAButton .customDice input {
        width: 40px;
        margin-bottom: 0px;
    }
}
