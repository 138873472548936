.flex-container {
  display: flex;
}

.flex-item {
  flex: 4;
  order: 2;
}

.flex-item:nth-child(2) {
  order: 1;
  background-color: grey;
  flex: 1;
}

@media screen and (max-width:640px) {
  .flex-container { flex-wrap: wrap; }
  .flex-item:first-child { flex-basis: 100%; }
  .flex-item:nth-child(2) { text-align:left; }
  .flexSelect {
    border: 1px solid gray;
    width: calc(100% - 2px);
  }
}
@media screen and (max-width:640px) {
  .mobile_hidden, .mobile_hidden input {
    display:none;
  }
}

@media print {
  .advancementControls {
    display:none;
  }
  .flex-item:nth-child(2) {
    flex: 0;
  }
  header.cleverorc {
    display:none;
  }
  .menuControls {
    display:none;
  }
  /*Adjust top banner for better print display (removing black background and white text)*/
  div.monsterDisplay div.sbName {
    background-color: none;
    padding: 0.3em;
    border: 1px solid black;
  }
  div.monsterDisplay div.sbName .bLabel {
    color: black;
  }
}
